:root {
  --gray-shade-1: #CBD5E1;
  --gray-shade: #F2F4FB;
  --dark-gray: #C2CDEF;
  --dark-gray-1: #94A3B8;
  --white: #FFFFFF;
  --light-gray: #64748B;
  --light-gray-1: #EDF2FF;
  --light-gray-2: #F1F5F9;
  --light-gray-3: #E2E8F0;
  --common-black: #1E293B;
  --silver: #1E293B26;
  --violet: #3D1D76;
}

.cardtitle-color {
  color: #334155 !important;
}

.purple-color {
  color: #3d1d76 !important;
}

.purple-L1 {
  color: #6D3DC5 !important;
}

.purple-L2 {
  color: #8155CF !important;
}

.purple-L3 {
  color: #7B69EB !important;
}

.purple-L4 {
  color: #8195FC !important;
}

.grey-color {
  color: #334155 !important;
}

.black-text-color {
  color: #1E293B !important;
}

.red {
  color: #ff0000 !important;
}

.grey-tag {
  color: #334155 !important;
}

.purple-tag {
  color: '#3D1D76' !important
}

.red-tag {
  color: #FF5353 !important
}

.black-tag {
  color: #475569 !important;
}

.green-tag {
  color: #6A934A !important
}

.bg-dark-green {
  background-color: #01A168 !important;
}

.amber-tag {
  color: #F4B91F;
}

.bg-orange {
  background-color: #F4B91F;
}

.bg-light-green {
  background-color: #66d10f;
}

.bg-light-lightBlue {
  background-color: #3787F9;
}

.bg-red-notification {
  background-color: #FF6363;
}

.blue-tag {
  color: #3787F9;
}

/* background */
.bg-blue {
  background: #3a83f8 !important;
}

.bg-red {
  background-color: #ff0000 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-puple {
  background-color: #3d1d76 !important;

}
.bg-light-gray-3 {
  background-color: var(--light-gray-3) !important;
}
.yellow-color {
  color: gold !important;
}

.white-color {
  color: #FFFFFF;
}

.common-text-color {
  color: #1E293B !important;
}

.light-common-label-color {
  color: #475569 !important;
}

.occasion-bg-color {
  background: #F1F5F9 !important;
}

/* .occasion-bg-color:hover {
  background: #E2E8F0 !important;
} */

.hover-tab-color-change:hover {
  background: #E2E8F0 !important;
}

.bg-purple {
  background-color: #7627c0;
}

.light-grey {
  color: #64748B !important;
}

.grey-nav {
  color: #CBD5E1 !important;
}

.required-field {
  color: #FF4C4C;
}