.esep-grid {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 50vh !important;
    grid-template-areas:
        'esepFirst esepSecond esepThird esepFourth'
        'esepFirst esepSecond esepThird esepFourth'
        'esepFirst esepSecond esepThird esepFourth'
        'esepFirst esepSecond esepThird esepFourth'
    ;
    grid-gap: 20px;
}

.esep-first {
    grid-area: esepFirst;
}

.esep-second {
    grid-area: esepSecond;
}

.esep-third {
    grid-area: esepThird;
}

.esep-fourth {
    grid-area: esepFourth;
}

.viewForm-grid-popups {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    /* height: 46vh !important;
    overflow-y: scroll; */
    grid-template-areas:
        'addFormFirst addFormSecond addFormThird'
        'addFormFirst addFormSecond addFormThird'
        'addFormFirst addFormSecond addFormThird'
        'addFormFirst addFormSecond addFormThird'
    ;
    /* grid-gap: 20px; */
}

.addForm-grid-popup {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 65vh !important;
    overflow-y: scroll;
    grid-template-areas:
        'addFormFirst addFormSecond'
        'addFormFirst addFormSecond'
        'addFormThird addFormFourth'
        'addFormThird addFormFourth'
    ;
    /* grid-gap: 20px; */
}
.justify-style {
    height: 75vh !important;
  }
  
  .normal-style {
    height: 66vh !important;
  }

.addForm-first {
    grid-area: addFormFirst;
}

.addForm-second {
    grid-area: addFormSecond;
}

.addForm-third {
    grid-area: addFormThird;
}

.addForm-fourth {
    grid-area: addFormFourth;
}

.iconStyle {
    height: 14px;
}

.warningText {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    margin-top: -1.4rem;
    margin-left: 1.3rem;
    font-size: 0.7rem;
    line-height: 20px;
    color: red;
}

.documents_cards {
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 0.625rem;
    margin-top: 1em;
    height: 4.5rem;
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
}

.esep-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 10px 12px 10px;
    background: #E2E8F0;
    border-radius: 6px;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: #1E293B;
}

.esep-placeholder {
    height: 43px;
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.esep-placeholder-editable {
    height: 43px;
    background: #F2F4FB;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    border-radius: 12px;
    border: 1px solid #F2F4FB !important;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.toggleStyle {
    display: flex;
    flex-wrap: wrap;
}
.toggleStyle-s {
    display: flex;
    flex-wrap: wrap;
}

.popup-SubBody {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
}

.trackerTimeline {
    display: flex;
    width: 7rem;
    align-items: flex-start;
    margin-top: 15px;
}

.dashboardTrackingCard {
    margin-top: 0.5rem;
    width: 73rem;
    height: 59vh;
}

.infoIconStyle {
    display: flex;
    align-self: start;
    margin-top: 10px;
}

/* ---------------------------------------------------------------- */
.enabledisablebox {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.enabledisableIcon {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-clip: padding-box;
}

.hzWidth {
    width: -webkit-fill-available;
}

.enable-disable-dashboard {
    max-height: 70vh;
    min-height: 65vh;
    overflow-y: auto;
}

.infosize {
    height: 17px;
    width: 17px;
}

/*-----Esep Tracking Dashboard Tooltip Styles----- */

.infoIconLabel::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}


.infoIconLabel:hover::before,
.infoIconLabel:hover::after {
    --scale: 1;
}

.infoIconLabel::after {
    content: '';
    /* border-top: 10px solid transparent;
    border-bottom: 0px solid transparent; */
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.infoIconLabel::before,
.infoIconLabel::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-left: -9%;
    width: 20% !important;
    margin-top: 1.8%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

/*-----Admin Esep Filter And Export Icons Tooltip Styles----- */

.filterIconLabel::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}

.filterIconLabel:hover::before,
.filterIconLabel:hover::after {
    --scale: 1;
}

.filterIconLabel::after {
    content: '';
    /* border-top: 10px solid transparent;
    border-bottom: 0px solid transparent; */
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.filterIconLabel::before,
.filterIconLabel::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-left: -3%;
    width: 8% !important;
    margin-top: -3.2%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

/* ---------------------------------------------------------------- */

.infoSalary::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}

.infoSalary::before,
.infoSalary::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-right: 90%;
    width: 80% !important;
    margin-top: -18%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

.infoSalary::after {
    content: '';
    /* border-top: 10px solid transparent;
    border-bottom: 0px solid transparent; */
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.infoSalary:hover::before,
.infoSalary:hover::after {
    --scale: 1;
}

/*-----Admin Gems Exports Tooltip Styles----- */
.exportIconLabel::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}

.exportIconLabel:hover::before,
.exportIconLabel:hover::after {
    --scale: 1;
}

.exportIconLabel::after {
    content: '';
    /* border-top: 10px solid transparent;
    border-bottom: 0px solid transparent; */
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.exportIconLabel::before,
.exportIconLabel::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-left: -3%;
    width: 10% !important;
    margin-top: -3.2%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

/* ---------------------------------------------------------------- */
@media (max-width: 768px) {
    .esep-grid {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        width: 100%;
        height: 50vh !important;
        grid-template-areas:
            'esepFirst esepFirst esepSecond esepSecond'
            'esepThird esepThird esepFourth esepFourth'
        ;
        grid-gap: 20px;
    }

    .addForm-grid-popup {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        width: 100%;
        height: 60vh !important;
        grid-template-areas:
            'addFormFirst addFormSecond'
            'addFormFirst addFormSecond'
            'addFormThird addFormFourth'
            'addFormThird addFormFourth'
        ;
        /* grid-gap: 20px; */
    }

    .viewForm-grid-popups {
        display: grid !important;
        /* grid-template-columns: 1fr; */
        grid-template-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        height: 50vh !important;
        grid-auto-rows: 100px;
        grid-template-areas:
            'addFormFirst addFormFirst addFormFirst'
            'addFormSecond addFormSecond addFormSecond'
            'addFormThird addFormThird addFormThird'
        ;
        grid-gap: 20px;
    }

    .doc-view {
        display: grid;
        margin-top: 49rem;
        padding: 13px;
    }

    .mleft-15 {
        margin-left: 15px !important;
    }

    .btnBox {
        box-shadow: 0px -3px 7px rgb(30 41 59 / 16%);
    }
}

@media (max-width: 576px) {
    .esep-grid {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        width: 100%;
        height: 50vh !important;
        grid-template-areas:
            'esepFirst esepFirst esepFirst esepFirst'
            'esepSecond esepSecond esepSecond esepSecond'
            'esepThird esepThird esepThird esepThird'
            'esepFourth esepFourth esepFourth esepFourth'
        ;
        grid-gap: 20px;
    }

    .addForm-grid-popup {
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        width: 100%;
        height: 50vh !important;
        grid-template-areas:
            'addFormFirst'
            'addFormSecond'
            'addFormThird'
            'addFormFourth'
        ;
        grid-gap: 20px;
    }

    .viewForm-grid-popups {
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        width: 100%;
        height: 50vh !important;
        grid-template-areas:
            'addFormFirst'
            'addFormSecond'
            'addFormThird'
        ;
        grid-gap: 20px;
    }

    .doc-view {
        display: grid;
        margin-top: 49rem;
        padding: 13px;
    }

    .mleft-15 {
        margin-left: 15px !important;
    }

    .btnBox1 {
        bottom: 0px !important;
        box-shadow: 0px -3px 7px rgb(30 41 59 / 16%);
    }
}

.contact-field {
    display: flex;
    align-items: center;
    background-color: #e9ecef;
}

.email-feild {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.125rem; /* 50px -> 50 / 16 = 3.125rem */
    height: 100%;
}

.email-feild img {
    height: 1.125rem; /* 18px -> 18 / 16 = 1.125rem */
    width: 1.125rem; /* 18px -> 18 / 16 = 1.125rem */
    background-color: #e9ecef;
}

.masked-email-div {
    display: flex;
    gap: 1.875rem; /* 30px -> 30 / 16 = 1.875rem */
    align-items: center;
}

.masked-email {
    width: 6.5625rem; /* 105px -> 105 / 16 = 6.5625rem */
    white-space: nowrap;        
    overflow-x: auto;         
    overflow-y: hidden;
}

.masked-contact {
    min-width: 5rem; /* 80px -> 80 / 16 = 5rem */
    max-width: 6.25rem; /* 100px -> 100 / 16 = 6.25rem */
}

.masked-email::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}
