.dropdown-Text {
    margin-right: 1.875rem;
    min-width: max-content;
}

.gemsMaitenanceFormBody {
    margin-bottom: 3rem !important;
    height: 12rem;
}

.gemsMaintenance_popup_scroller {
    overflow-y: auto !important;
    overflow-x: hidden;
    min-height: 30vh !important;
    max-height: 30vh !important;
}

.gemsAdmin thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    /* z-index: 1001 */
}

.w-100 {
    width: 100% !important;
}

.w-70 {
    width: 70%;
}

.note {
    font-weight: 700;
}

.sliderImage {
    width: 50%;
}

.colorCodeInput {
    display: flex;
    align-items: center;
}

.colorBox {
    width: 70px;
    height: 20px;
    border-radius: 5px;
}

#colorCode {
    -webkit-appearance: none;
    padding: 0;
    border: none;
    border-radius: 10px;
    width: 30%;
    height: 25px;
}

#colorCode::-webkit-color-swatch {
    border: none;
    border-radius: 10px;
    padding: 0;
}

#colorCode::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 10px;
    padding: 0;
}