.card-new {
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);
    border-radius: 20px;
}

.common-card {
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9 !important;
    background-blend-mode: soft-light, normal !important;
    border: 1.5px solid #FFFFFF !important;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(51, 65, 85, 0.26) !important;
    border-radius: 20px !important;
}

.card-inner {
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
    margin: 0px;
    padding: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.card-for-click {
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
    margin: 0px;
    padding: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.card-for-click:hover {
    background: linear-gradient(90deg, #3D1D76A3 0%, #653DAF 100%);
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
    margin: 0px;
    padding: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.card-title-position {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
}

.righticon {
    padding: 8px;
    margin-left: 35%;
    margin-top: 7px;
    height: 30px;
    width: 30px;
    background: #F2F4FB;
    border: 1px solid #F7F7FD;
    box-sizing: border-box;
    box-shadow: -5px -5px 20px #FFFFFF, 5px 5px 20px rgba(174, 174, 192, 0.5);
    border-radius: 50%;
    cursor: pointer;
}

.container-fluid {
    padding: 0px !important;
}

.cardnotification {
        background: #F1F5F9!important;
        border: 1.5px solid #FFFFFF !important;
        box-sizing: border-box !important;
        border-radius: 12px !important;
}

.card-detail-div {
    margin-left: 19px;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.card-head{
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 3px;
    margin-top: 7px;
}

.card-details-lbl{
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    color: #1E293B;
}

.card-field-value {
    font-size: .875rem;
}