.m-5px {
    margin: 5px;
}

.m-10px {
    margin: 10px;
}
.mt-height{
    height: 20px !important;
    margin-top: 0.6em !important;
}

.mt-10_2px {
    margin-top: 8px;
}

.mt-1em {
    margin-top: 1em;
}

.mt-0px {
    margin-top: 0px !important;
}

.m-8px {
    margin: 8px;
}

.m-26px {
    margin: 26px;
}

.m-2rem {
    margin: 2rem;
}

.m-30px {
    margin: 30px !important;
}

.mt-30px {
    margin-top: 30px;
}

.mt-45px {
    margin-top: 45px;
}

.mx-30px {
    margin-left: 30px;
    margin-right: 30px;
}

.ml-15px {
    margin-left: 15px !important;
}

.ml-19px{
    margin-left: 19px !important;
}

.m-15px {
    margin: 15px;
}

.mt-15percent {
    margin-top: 15%;
}

.mt-2px {
    margin-top: 2px;
}

.mt-6px {
    margin-top: 6px;
}

.mt-36px {
    margin-top: 36px;
}

.mr-4_5rem {
    margin-right: 4.5rem;
}

.mr-5rem {
    margin-right: 5rem;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-5px {
    margin-right: 5px;
}

.mr-4px {
    margin-right: 4px;
}

.mx-15px {
    margin-right: 15px;
    margin-left: 15px;
}

.mx-20px {
    margin-right: 20px;
    margin-left: 20px;
}

.mx-25px {
    margin-right: 25px;
    margin-left: 25px;
}

.mx-10px {
    margin-left: 10px;
    margin-right: 10px;
}

.ml-10px {
    margin-left: 10px !important;
}

.ml-0px {
    margin-left: 0px !important;
}

.mr-0px {
    margin-right: 0px !important;
}


.mr-15px {
    margin-right: 15px;
}

/* .px-30px {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
} */
.-ml-20px {
    margin-left: -20px;
}

.ml-35px {
    margin-left: 35px !important;
}

.ml-5px {
    margin-left: 5px;
}

.mr-20px {
    margin-right: 20px;
}

.ml-55px {
    margin-left: 55px;
}

.ml-60px {
    margin-left: 60px;
}

.mlm-30px {
    margin-left: -30px;
}

.ml-25px {
    margin-left: 25px;
}

.mlm-25px {
    margin-left: -25px;
}

/* dont remove important */
.mt-38px {
    margin-top: 38px !important;
}

.mt-10px {
    margin-top: 10px;
}

.mt-15px {
    margin-top: 15px;
}

.mt-14px {
    margin-top: 14px;

}

.mt-17px {
    margin-top: 17px;
}

.mt-12px {
    margin-top: 12px;
}

.mt-19px {
    margin-top: 1.188rem;
}

.mt-20px {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25%;
}

.mt-25px {
    margin-top: 25px;
}

.mt-10p {
    margin-top: 10%;
}

.mt-30 {
    margin-top: 30%;
}

.mt-50p {
    margin-top: 50%;
}

.mb-24px {
    margin-bottom: 1.5rem !important;
}

.mb-21px {
    margin-bottom: 21px;
}

.mb-0px {
    margin-bottom: 0px;
}
.mb-4px{
    margin: -5px !important;
}

.mb-20px {
    margin-bottom: 20px;
}

.mb-30px {
    margin-bottom: 30px;
}

.mb-5px {
    margin-bottom: 5px;
}

.mt-9 {
    margin-top: 9%;
}

.mt-9px {
    margin-top: 9px;
}

.mt-5px {
    margin-top: 5px !important;
}

.mt-15 {
    margin-top: -15px;
}

.mt-100 {
    margin-top: 1px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15px {
    margin-bottom: 15px;
}

.mb-10vh {
    margin-bottom: 10vh !important;
}

.mb-50px {
    margin-bottom: 50px;
}

.ml-10 {
    margin-left: 15px !important;
}
.p-sticky-fr{
    position: sticky;
    float: right;
    margin-top: 10px;
}
.height-max-min{
    height: auto !important;
    min-height: 45% !important;
    max-width: 65% !important;
}
.ml-10px {
    margin-left: 10px;
}

.m-auto {
    margin: auto auto auto 0;
}

.ml-15per {
    margin-left: 15%;
}

.ml-m8px {
    margin-left: -8px;
}


.ml-m10px {
    margin-top: -10px;
}

.ml-06rem {
    margin-left: 0.6rem;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-14rem {
    margin-left: 12rem !important;
}

.ml-7rem {
    margin-left: 7rem !important;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-30 {
    margin-left: 30px;
}

.mr-10px {
    margin-right: 10px;
}

.mt-2rem {
    margin-top: 2rem !important;
}

.ml-2_5rem {
    margin-left: 2.5rem;
}

/* paddings */
.pl-0px {
    padding-left: 0px !important;
}

.pr-0px {
    padding-right: 0px !important;
}

.pr-20px {
    padding-right: 20px;
}

.pl-15px {
    padding-left: 15px !important;
}

.pr-15px {
    padding-right: 15px;
}

.p-5px {
    padding: 5px;
}

.p-lrb-5px {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

.p-10px {
    padding: 10px !important;
}

.p-20px {
    padding: 20px !important;
}

.p-40px {
    padding: 40px !important;
}

.pt-24px {
    padding-top: 1.5rem !important;
}

.pb-24px {
    padding-bottom: 1.5rem !important;
}

.p-10rem {
    padding: 10rem;
}

.pb-25px {
    padding-bottom: 25px;
}

.px-24px {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5px {
    padding-left: 5px;
    padding-right: 5px;
}

.pl {
    padding-left: 4rem;
}

.pr {
    padding-right: 30px;
}

.px-20px {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

/* do not remove important  */
.px-30px {
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.px-10px {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

/* common padding */
.pr-12px {
    padding-right: 12px !important;
}

.pr-10px {
    padding-right: 10px !important;
}

.pr-18px {
    padding-right: 18px !important;
}

.pr-45px {
    padding-right: 45px !important;
}

.pl-10px {
    padding-left: 12px !important;
}

.pl-12px {
    padding-left: 12px !important;
}

.pt-12px {
    padding-top: 12px !important;
}

.pt-14px {
    padding-top: 14px !important;
}

.pb-12px {
    padding-bottom: 12px !important;
}

.pb-5px {
    padding-bottom: 5px;
}

.pb-1rem {
    padding-bottom: 1rem !important;
}

.ph-1_875rem {
    padding: 0 1.875rem !important;
}

.ph-1rem {
    padding: 0 1rem !important;
}

.pt-5px {
    padding-top: 5px;
}

.p-0px {
    padding: 0px;
}

.mb-10px {
    margin-bottom: 10px !important;
}

.mb-1rem {
    margin-bottom: 1rem !important;
}

.mt-1rem {
    margin-top: 1rem;
}

.mt-1_5rem {
    margin-top: 1.3rem;
}

.mt-3rem {
    margin-top: 3rem;
}

.mt-2_5rem {
    margin-top: 2.5rem;
}

.mt-3px {
    margin-top: -3px;
}

.mt-5rem {
    margin-top: 5rem !important;
}

.mt-6rem {
    margin-top: 8rem !important;
}

.mt-7rem {
    margin-top: 8rem !important;
}

.mt-8rem {
    margin-top: 8rem !important;
}

.mt-8 {
    margin-top: 8% !important;
}

.mt_52 {
    margin-top: -2.8rem;
}

.px-12px {
    padding-right: 12px !important;
    padding-left: 12px !important;
}
.px-50px {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

/* .px-30px {
    padding-right: 50px !important;
    padding-left: 50px !important;
} */

.p-12px {
    padding-right: 12px !important;
    padding-left: 12px !important;
    padding-top: 12px !important;
    /* padding-bottom: 12px !important; */
}

.m-lr {
    margin-left: 11px;
    margin-right: 11px;
}

.p-11px {
    padding: 10px !important;
}

.p-13px {
    padding: 12px !important;
}

.p {
    padding: 20px !important;
}

.p-15px {
    padding: 15px !important;
}

.p-lr {
    padding-left: 20px;
    padding-right: 20px;
}

/* <hr /> line style */

.p-hr-lr {
    padding-left: 11px;
    padding-right: 11px;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-20 {
    padding-left: 20px;
}

.p-lrtb-10px {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.hr-mt-mb-7 {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.padding-inline {
    padding-inline-end: 20px;
    padding-inline-start: 20px;
}

/* heights */
.h-8rem {
    height: 8rem;
}

.h-10 {
    height: 10% !important;
}

.h-85{
    height: 85% !important;
}

.h-31px {
    height: 31px !important;
}

.h-35px {
    height: 35px !important;
}

.h-41px {
    height: 41px !important;
}

.h-56px {
    height: 56px !important;
}

.h-36px {
    height: 36px;
}
.h-38px{
    height: 38px !important;
}

.h-100 {
    height: 100% !important;
}

.h-30 {
    height: 30%;
}

.h-90 {
    height: 90% !important;
}

.h-60-percent {
    height: 60%;
}

.h-70 {
    height: 70%;
}

.h-80 {
    height: 80%;
}

.h-5rem {
    height: 5rem !important;
}

.h-19rem {
    height: 19rem !important;
}

.h-21rem {
    height: 21rem !important;
}

.h-22rem {
    height: 22rem !important;
}

.h-24rem {
    height: 24rem !important;
}

.h-23rem {
    height: 23.5rem !important;
}

.h-10vh {
    height: 10vh !important;
}

.h-7vh {
    height: 7vh;
}

.h-90-percent {
    height: 90%;
}

.h-93-percent {
    height: 93%;
}

.h-95-percent {
    height: 95%;
}

.h-20vh {
    height: 20vh !important;
}

.h-21vh {
    height: 21vh;
}

.h-23vh {
    height: 23vh;
}

.h-27vh {
    height: 27vh !important;
}

.h-38vh {
    height: 38vh !important;
}

.h-40vh {
    height: 40vh !important;
}

.h-57vh {
    height: 57vh;
}

.h-6vh {
    height: 6vh;
}

.h-66vh {
    height: 66vh;
}

.h-40px {
    height: 40px;
}

.h-93-percent {
    height: 93%;
}

.h-97-percent {
    height: 97%;
}

.h-63-percent {
    height: 63% !important;
}

.h-28vh {
    height: 28vh;
}

.h-30vh {
    height: 30vh !important;
}

.h-31vh {
    height: 31vh;
}

.min-h-40vh {
    min-height: 40vh !important;
}

.min-h-48vh {
    min-height: 48vh !important;
}

.h-42vh {
    height: 42vh !important;
}

.h-48vh {
    height: 48vh;
}

.h-49vh {
    height: 49vh;
}

.h-50vh {
    height: 50vh !important;
}

.h-54vh {
    height: 54vh !important;
}

.h-52vh {
    height: 52vh !important;
}

.h-100vh {
    height: 100vh !important;
}

.h-59vh {
    height: 59vh !important;
}

.h-60vh {
    height: 60vh !important;
}

.h-64vh {
    height: 64vh !important;
}

.h-70vh {
    height: 70vh !important;
}

.h-70-overflow {
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
}

.h-71vh {
    height: 71vh;
}

.h-72vh {
    height: 72vh;
}

.h-80 {
    height: 80% !important;
}

.h-70 {
    height: 70% !important;
}

.h-75vh {
    height: 75vh !important;
}

.h-20 {
    height: 20% !important;
}

.h-30 {
    height: 30% !important;
}

.h-40 {
    height: 40% !important;
}

.h-50 {
    height: 50% !important;
}

.h-244px {
    height: 244px !important;
}

.h-36px {
    height: 36px !important;
}

.h-601px {
    height: 37.563rem !important;
}

.h-537px {
    height: 33.563rem !important;
}

.h-42px {
    height: 44px !important;
}

.h-46px {
    height: 46px !important;
}

.h-70px {
    height: 70px !important;
}

.h-12vh {
    height: 12vh;
}

.h-25vh {
    height: 25vh;
}

.h-37vh {
    height: 37vh;
}

.h-46vh {
    height: 46vh;
}

.h-53vh {
    height: 53vh;
}

.h-52vh {
    height: 52vh;
}

.h-58vh {
    height: 58vh;
}

.h-65vh {
    height: 60.5vh;
}

.h-66vh {
    height: 65vh;
}

.h-67vh {
    height: 67vh;
}

.h-80vh {
    height: 80vh;
}

.h-68vh {
    height: 68vh;
}

.h-15 {
    height: 15%;
}

.h-15px {
    height: 15px;
}

.h-15vh {
    height: 15vh;
}
.min-h70px {
    min-height: 70px;
}
/* width */
.w-25px {
    width: 25px;
}

.w-150px {
    width: 150px;
}

.w-0px {
    width: 0px;
}

.w-7 {
    width: 7%;
}

.w-10 {
    width: 10%;
}

.w-21 {
    width: 21%;
}

.w-28 {
    width: 28%;
}

.w-29 {
    width: 29%;
}

.w-45-percent {
    width: 45%;
}
.w-99-percent {
    width: 99%;
}

.w-8rem {
    width: 8rem;
}

.w-25rem {
    width: 25rem !important;
}

.w-15px {
    width: 15px;
}

.w-15 {
    width: 15%;
}

.w-16 {
    width: 16%;
}

.w-11rem {
    width: 11rem;
}

.w-15rem {
    width: 15rem;
}

.w-17rem {
    width: 17rem;
}

.w-17vw {
    width: 17.5vw !important;
}

.w-20 {
    width: 20% !important;
}

.w-20px {
    width: 20px;
}

.w-35px {
    width: 35px;
}

.w-25 {
    width: 25% !important;
}

.w-10rem {
    width: 10rem !important;
}

.w-35rem {
    width: 35rem !important;
}

.w-50rem {
    width: 45rem !important;
}

.w-60rem {
    width: 60rem !important;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.w-57 {
    width: 57% !important
}

.w-5 {
    width: 5% !important;
}

.w-2 {
    width: 2%;
}

.w-14 {
    width: 14% !important;
}

.w-14vw {
    width: 14vw !important;
}

.w-8rem {
    width: 8rem !important;
}
.w-10rem {
    width: 10rem !important;
}
.w-14rem {
    width: 14rem !important;
}
.w-12rem {
    width: 12rem !important;
}
.w-auto {
    width: auto !important;
}
.w-13rem {
    width: 13rem !important;
}
.w-14rem {
    width: 14rem !important;
}
.w-16rem {
    width: 16rem !important;
}
.w-20rem {
    width: 20rem !important;
}

.w-40 {
    width: 40%;
}

.w-42 {
    width: 42%;
}

.w-30 {
    width: 30%;
}

.w-30rem {
    width: 30rem !important;
}
.w-32{
    width: 32%;
}

.w-32rem {
    width: 32rem;
}

.w-33 {
    width: 33%;
}

.w-32 {
    width: 32%;
}

.w-47 {
    width: 47% !important;
}

.w-58 {
    width: 58% !important;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60% !important;
}

.w-62 {
    width: 62% !important;
}

.w-62-percent {
    width: 62% !important;
}

.w-70 {
    width: 70% !important;
}

.w-77 {
    width: 77% !important;
}

.w-79 {
    width: 79% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.w-94 {
    width: 94% !important;
}
.w-95 {
    width: 96% !important;
}

.w-97 {
    width: 98% !important;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-35 {
    width: 35%;
}

.w-34 {
    width: 40%;
}

.w-480px {
    width: 30rem !important;
}

.w-242px {
    width: 242px !important;
}

.w-150px {
    width: 150px !important;
}

.w-36px {
    width: 36px !important;
}

.w-46px {
    width: 46px !important;
}

.w-65px {
    width: 65px !important;
}

.w-70px {
    width: 70px !important;
}

.w-199px {
    width: 12.438rem !important;
}

.w-75vw {
    width: 75vw !important;
}

.w-60vw {
    width: 60vw !important;
}

.w-80vw {
    width: 80vw !important;
}

.w-85vw {
    width: 85vw !important;
}

.w-90vw {
    width: 90vw !important;
}

.MW-30-100 {
    width: 30%;
}

.mw-inherit {
    max-width: inherit !important;
}

.height-245 {
    height: 245px !important;
}

.min-height-200px {
    min-height: 200px !important;
}

.min-width-130px {
    min-width: 130px !important;
}

.h-20px {
    height: 20px
}

.height-200 {
    height: 200px !important;
}

.height-210 {
    height: 210px !important;
}

.height-185 {
    height: 185px !important;
}

.width {
    width: fit-content;
}

.height-41vh {
    height: 41vh;
}

.height-44vh {
    height: 44vh;
}

.max-height-30vh {
    max-height: 30vh;
}

.max-height-35vh {
    max-height: 35vh;
}

.min-height-50vh {
    max-height: 50vh;
}


.max-height-40vh {
    max-height: 40vh !important;
}

.max-height-45vh {
    max-height: 45vh !important;
}
.max-height-43vh {
    max-height: 43vh !important;
}

.max-height-50vh {
    max-height: 50vh !important;
}

.max-height-33vh {
    max-height: 33vh !important;
}

.max-height-80 {
    max-height: 80% !important;
}

.max-height-90 {
    max-height: 90% !important;
}

.max-height-100 {
    max-height: 100% !important;
}

.h-43px {
    height: 43px !important;
}

.hz-scroller {
    width: 100%;
    overflow-x: scroll !important;
}

.width-85 {
    width: 85% !important;
}

.scroll-y {
    overflow-y: scroll;
}

.scroll-x {
    overflow-x: scroll;
}

.scroll-auto {
    overflow-y: auto;
}
.scroll-auto-x {
    overflow-x: auto;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.mLRT {
    margin-top: 17px;
    margin-left: 10px;
    margin-right: 10px;
}

.mLRT_Manager {
    margin-top: -4px;
    margin-left: 10px;
    margin-right: 10px;
}

.scroll-webkit-x {
    display: flex;
    flex-direction: row;
    width: max-content;
}

/* -------Compulsary field (*) css--------- */
.required:after {
    content: " *";
    color: red;
}

.max-h-53vh {
    max-height: 53vh;
}

.max-h-46vh {
    max-height: 46vh;
}

@media only screen and (max-width: 800px) {
    .MW-30-100 {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) and (min-width: 800px) {
    .MW-30-100 {
        width: 45%;
    }
}

hr {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
}

.wh {
    width: 56px;
    height: 49px;
}

.paddingTop-11 {
    padding-top: 11px !important;
}

.p-10 {
    padding: 10px !important;
}

.pointer {
    cursor: pointer;
}

.pl-23px {
    padding-left: 23px !important;
}

.pl-20px {
    padding-left: 20px !important;
}

.pl-15px {
    padding-left: 15px !important;
}

.pb-10px {
    padding-bottom: 10px !important;
}

.pb-20px {
    padding-bottom: 20px !important;
}

.pt-10px {
    padding-top: 10px;
}

.pt-20px {
    padding-top: 20px !important;
}

.py-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.py-10px {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.mlrt-10px {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 10px !important;
}

.mt-7px {
    margin-top: 7px !important;
}

.p-4px {
    padding: 4px !important;
}

.p-0 {
    padding: 0px;
}

.ml-65px {
    margin-left: 65px;
}

.italic-Font {
    font-style: italic !important;
}

.unineue-font {
    font-family: 'UniNeue-Regular', Sans-serif;
}

.vertical-padding-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.transfertable {
    overflow-y: auto;
    overflow-x: hidden;
    height: 50vh;
}

.transferinner-scroll-table {
    overflow-y: auto !important;
}

.transferRequestform {
    overflow-y: auto;
    height: 70vh;
    padding-bottom: 5px;
    margin-bottom: 7px;
    min-height: 50vh;
    max-height: 64vh;
}

.mom_slider {
    height: 44vh !important;
}

.mom_slider_image {
    height: 70%;
    width: 42%;
}

.mom_modal {
    border-radius: 10px !important;
    padding: 5px;
    overflow-x: hidden !important;
    width: 600px !important;
    background-color: #fff !important;
}

/* .alice-carousel__stage {
    left: 50% !important;
} */

.alice-carousel__stage-item {
    width: 100% !important;
}

/* .alice-carousel__stage-item {
    width: 500px !important;
} */

.mom-wishText {
    background: linear-gradient(90.05deg, #3D1D76 0.04%, #653DAF 101.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    line-height: 110%;
}

.w40px {
    width: 40px;
}

.getAppText {
    color: #653DAF;
}

.text-white {
    color: #ffff
}

.border_radius_12px {
    border-radius: 12px !important;
}

.min-height-45vh {
    min-height: 45vh;
}

.popup-calender {
    position: fixed;
    background: #0000001c;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-x-clip {
    overflow-x: clip;
}

.seperator {
    border: 2px solid var(--gray-shade-1);
}

.line-horizontal {
    border-bottom: 1px solid var(--gray-shade-1);
}

.blue-button {
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
    padding: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.hw-25px {
    height: 25px;
    width: 25px;
}

.no-wrap {
    flex-wrap: nowrap !important;
}

.gap-5px {
    gap: 5px;
}
.gap-4px {
    gap: 4px;
}

.rotate-div-90 {
    rotate: 90deg;
}

.rotate-div-90deg {
    rotate: -90deg;
}

.ptb-0_375 {
    padding-block: .375rem;
}

.plr-0_75 {
    padding-inline: .75rem;
}

.px-20px {
    padding-inline: 20px;
}

.gap-7px {
    gap: 7px;
}

.bottom-10px {
    position: relative;
    bottom: 10px;
}
.p-r {
    position: relative;
}

.row-gap-10px {
    row-gap: 10px;
}

.gap-100px {
    gap: 100px;
}
.of-hidden{
    overflow: hidden;
}

.height-overflow{
    height: 55vh !important;
    overflow: hidden;
    overflow-y: auto;
}

.sugg-container{
    width: 70%;
    height: 60%;
    overflow: hidden;
    margin-top:10px;
    margin-bottom: 10px;
}

.sugg-container-Image{
    width: 100%;
    height: 100%;
    object-fit: cover
}

.sugg-container1{
    width: 70%;
    height: 60%;
    overflow: hidden;
    margin-top:10px;
    margin-bottom: 10px;
}
  
.sugg-container2{
    width: 70%;
    height: 60%;
    overflow: hidden;
    margin-top:5px
}

.grid-center{
    display: grid;
    place-items: center;
    height: 50vh;
    width: 100%;
}

.flex-style-for-view-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #F1F5F9;
    border-radius: 0 0 20px 20px;
    border: 1px solid #dee2e6;
    border-top: 0;
    min-height: 50px;
    align-items: center;
}

.btm-pagination {
    padding: 0 !important;
}

.total-records{
    display: flex;
    width: 20%;
    align-items: center;
    font-weight: bold;
}

.font-weight-light{
    font-weight: lighter;
}

.number-box {
    width: 56px; 
    height: 36px;
    background-color: #ffffff; 
    border: 1px solid #ddd; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #333; 
}
.mask-data{
    position: absolute;
    right: 1rem;
    top: 45%;
    transform: translateY(-50%);
    cursor: pointer;
}

.mask-bank{
    position: absolute;
    right: 2rem;
    top: 60%;
    transform: translateY(-50%);
    cursor: pointer;
}

.mask-team-details{
    position: absolute;
    right: 2rem;
    top: 40%;
    transform: translateY(-50%);
    cursor: pointer;
}
